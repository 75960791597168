import axios from "axios";
import http from "./http-common";
import {URL_API} from "./common"

const user = JSON.parse(localStorage.getItem('user'));
class UploadFilesService {
  
  upload(nombre,file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("nombre", file);

    return http.post("/upload/"+nombre, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": 'Bearer ' + user.accessToken
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files",{headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": 'Bearer ' + user.accessToken
    }});
  }
  setFace(id,link, onUploadProgress){
    const data={
      id:id,
      link:link
    }
    return axios.post(`${URL_API}/facebook/facebook/create`,data,{headers: {
      
      "Authorization": 'Bearer ' + user.accessToken
    },onUploadProgress,})
  }
   getFace(){
  return axios.get(`${URL_API}/facebook/ultimo`)
  
  
 }
}

export default new UploadFilesService();
